// import { Icon } from 'astro-icon/components';
import React, { useRef, useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import { windowState } from '@/js/app';
import { debounce } from 'throttle-debounce';
import style from './Menu.module.scss';
import { TOPIC_IDS, MEDIA_QUERIES } from '@/js/constants';
import { saveToLocalStorage, getFromLocalStorage } from '@/js/utils/browser';
// import HamburgerButton from '@/components/ui/HamburgerButton/HamburgerButton.astro';
import HamburgerButton from '@/components/ui/react/HamburgerButton/HamburgerButton';


import SiteLogo from '@/assets/img/common/logo-future-insights-lab-white.svg';
import ExternalIcon from '@/assets/icons/external.svg?react';

type Language = {
  code: 'ja' | 'en' | 'zh-CHS'
  label: 'JA' | 'EN' | 'CN'
}

type Settings = {
  lang: Language
}

const languages: Language[] = [
  {
    code: 'ja',
    label: 'JA',
  },
  {
    code: 'en',
    label: 'EN',
  },
  {
    code: 'zh-CHS',
    label: 'CN',
  },
];

interface Props {
  pathname: string;
  urlSearch?: string;
  isCeilingSticky?: boolean;
  children: React.ReactNode;
}

export default function Menu({ pathname, urlSearch, isCeilingSticky, children }: Props) {
  const [currentLang, setCurrentLang] = useState<string>('ja');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [isActiveExpand, setIsActiveExpand] = useState<boolean>(false);
  const [height, setHeight] = useState<'auto' | number>('auto');
  const expandEl = useRef<HTMLDivElement>(null);
  const targetHeight = useRef<number>(0);

  const routes = [
    {
      href: '/',
      label: 'R&D TOP',
      current: /^\/(\?|#.*)?$/,
    },
    {
      href: '/project/?type=latests',
      label: 'What’s New',
      current: /^\/project\/.*(\?|&)type=latests(\&|#|$)/,
    },
    {
      href: '/topics/',
      label: 'Topics',
      current: /^\/topics\/.*$/
    },
    {
      href: '/project/',
      label: 'Project',
      current: /^\/project\/(?!.*(\?|&)type=latests).*$/
    },
    {
      href: '/about/',
      label: 'About',
      current: /^\/about\/.*$/
    },
    {
      href: '/creativelab/',
      label: 'Creative Lab.',
      current: /^\/creativelab\/.*$/
    },
    {
      href: 'https://www.nomurakougei.co.jp/recruit/',
      label: 'Recruit',
    },
    {
      href: 'https://www.nomurakougei.co.jp/form/other/',
      label: 'Contact',
    },
  ];

  useEffect(() => {
    const callback = () => {
      /**
       * @doc #155 - スクリプト方式では、カレントの返り値と表示言語が異なるケースがあるため、返り値を使わない
       */
      const settings = getFromLocalStorage<Settings>('settings');
      const lang: Language['code'] = settings ? settings.lang.code : languages[0].code;

      handleChangeLanguage(lang);
      // const lang = (window.WOVN.io.getCurrentLang() as any)?.code || 'ja';
      // setCurrentLang(lang);
    }
    if (isWovnReady()) {
      callback()
    } else {
      window.addEventListener('wovnApiReady', callback)
    }

    if (window.matchMedia(MEDIA_QUERIES.MD).matches) {
      setIsActiveExpand(true);
    }

    PubSub.subscribe(TOPIC_IDS.WINDOW_SCROLL, () => {
      if (!isCeilingSticky) return;

      if (document.querySelector('.js-ceiling-sticky')?.classList.contains('is-scrolled')) {
        handleExpand();
      } else {
        handleClose();
      }
    });

    PubSub.subscribe(TOPIC_IDS.TOGGLE_MENU, (_, bool: boolean) => {
      setIsOpen(bool);
    });

    window.addEventListener('resize', debounce(100, () => {
      if (!isCeilingSticky) return;

      if (window.matchMedia(MEDIA_QUERIES.MD).matches) {
        updateHeight();
        setIsActiveExpand(true);
      } else {
        setIsActiveExpand(false);
      }
    }));

    if (isCeilingSticky) {
      updateHeight();
    }
  }, []);

  const isWovnReady = () => {
    // TODO: ドメインが許可されていない環境では、isApiReady() が false になり、UI動作が確認できない為、判定から除外しておく（エラーハンドリングとしては十分）
    return window.WOVN && window.WOVN.io /* && window.WOVN.io.isApiReady(); */
  }

  const updateHeight = () => {
    targetHeight.current = expandEl.current?.clientHeight || 0;
    setHeight(0);
  }

  const handleChangeLanguage = (newLang: string) => {
    const callback = () => {
      const lang = languages.find((it) => it.code === newLang) || languages[0];

      window.WOVN.io.changeLang(newLang);
      setCurrentLang(newLang);
      saveToLocalStorage<Settings>('settings', { lang });
    }
    if (!isWovnReady()) {
      window.addEventListener('wovnApiReady', callback);
      return;
    }
    callback();
  }

  const handleExpand = () => {
    setIsExpand(true);
    setHeight(targetHeight.current);
  }

  const handleClose = () => {
    setIsExpand(false);
    setHeight(0);
  }

  const isCurrentPage = (menuItem: any): boolean => {
    const pathWithQueryString = pathname + (urlSearch || '')
    if (!menuItem.current) {
      return false
    } else {
      return menuItem.current?.test(pathWithQueryString)
    }
  }

  return (
    <nav className={style.menu} data-open={isOpen}>
      <div className={style.menu__hamburger}>
        <HamburgerButton />
      </div>

      <div className={style.menu__container}>
        <hgroup className={style.menu__hgroup}>
          <h2 className={style.menu__heading}>
            <a href="/"  className={`${style.menu__logo} u-hover`}>
              <img src={SiteLogo.src} alt="未来創造研究所／Future Insights Lab.のロゴ" loading="lazy" />
            </a>
          </h2>
          <p className={style.menu__title}>Research and Development</p>
        </hgroup>
        <div className={[style.menu__content, isExpand ? 'is-expand' : ''].join(' ')}>
          <button type="button" className={style.menu__expander} onClick={handleExpand}>
            <span></span>
          </button>
          <div className={style.menu__expandContent} style={isCeilingSticky && isActiveExpand ? { height } : {}}>
            <div ref={expandEl}>
              <div className={style.menu__listContainer}>
                <ul className={style.menu__list}>
                  {routes.map((item) => {
                    const isExternal = !/^(\/|#|\/\#)/.test(item.href);
                    return (
                      <li key={item.href}>
                        <a
                          href={item.href}
                          className={style.menu__item}
                          target={isExternal ? '_blank' : '_self'}
                          aria-current={isCurrentPage(item) ? 'page' : false}>
                          {item.label}
                          {isExternal && (<ExternalIcon data-icon />)}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <ul className={style.menu__locales}>
                {languages.map((it) => (
                  <li key={it.code}>
                    <button type="button" onPointerDown={() => handleChangeLanguage(it.code)} aria-selected={currentLang === it.code}>
                      <span data-value={it.code}>{it.label}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
